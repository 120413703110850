import React from 'react'
import { Link } from 'react-router-dom'

const LandMenu = () => {
  return (
    <div className='container land-menu'>
      <div className='col-12'>
        <div className="row">
          <Link to='/WB'>
            <button className='btn btn-gold btn-block mb-4'>
              Weight and Balance
            </button>
          </Link>
          <Link to='/checklist'>
            <button className='btn btn-gold btn-block mb-4'>Checklist</button>
          </Link>
        </div>
        <div className="row">
          <Link to='/rfm'>
            <button className='btn btn-gold btn-block mb-4'>RFM</button>
          </Link>
          <Link to='/mel'>
            <button className='btn btn-gold btn-block mb-4'>MEL</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LandMenu
