import React from 'react'
import ChecklistBody from './ChecklistBody'

const BeforeLanding = (props) => {
  const items = [
    { c: 'ECL / Fuel Levers', r: 'FLY / DIRECT', id: 1 },
    { c: 'Heater / Bleed Air', r: 'OFF', id: 2 },
    { c: 'Caution Panel', r: 'Check', id: 3 },
    { c: 'Engine Instruments', r: 'Check', id: 4 },
    { c: 'EAPS', r: 'ON', id: 5 },
    { c: 'LDG Gear', r: 'DOWN, 3 Green', id: 6 },
    { c: 'LDG / Search Light', r: 'As Required', id: 7 },
    { c: 'WX Radar', r: 'STBY', id: 8 },
    { c: 'Parking Brake', r: 'OFF', id: 9 }, 
  ]
  return <ChecklistBody items={items} status={props.status}/>
}

export default BeforeLanding
