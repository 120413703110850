import React from 'react'

const Footer = () => {
  return (
    <footer className='page-footer'>
      <div className='col-6 offset-3'>
        <p className='text-center'>Made by Gluti s.r.o. &copy;</p>
      </div>
    </footer>
  )
}

export default Footer
