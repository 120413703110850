import React, { Fragment } from 'react'
import ChecklistComp from '../components/ChecklistComp'

const Checklist = () => {
  return (
    <Fragment>
      <ChecklistComp />
      <br />
    </Fragment>
  )
}

export default Checklist
