import React from 'react'

const ChecklistBody = (props) => {

  // After click on item turns the item green and back
  const changeStatus = (key) => {
    if(document.getElementsByClassName(`item-${key}`)[0].style.color === 'rgb(0, 255, 0)')
    {
      document.getElementsByClassName(`item-${key}`)[0].style.color = ''
      document.getElementsByClassName(`item-${key}`)[1].style.color = ''
    }
    else {
      document.getElementsByClassName(`item-${key}`)[0].style.color = '#00FF00'
      document.getElementsByClassName(`item-${key}`)[1].style.color = '#00FF00'
    }
  }
  return (
    <div className='row'>
      <div className='col-6 text-left pr-0'>
        <ul className='checklist'>
          {props.items.map((item) => {
            // Development stage, this shows only items without daily
            if(props.status === 1) {
              if (item.interval !== 'daily') {
                // HERE IS THE MAIN CODE
                return <li className={`item-${item.id}`} key={item.id} onClick={() => changeStatus(item.id)}>{item.c}</li>
              }
            }
            else {
              return <li className={`item-${item.id}`} key={item.id} onClick={() => changeStatus(item.id)}>{item.c}</li>
            }
          })}
        </ul>
      </div>
      <div className='col-6 text-right pl-0'>
        <ul className='checklist'>
          {props.items.map((item) => {
            if(props.status === 1) {
              if (item.interval !== 'daily') {
                return <li className={`item-${item.id}`} key={item.id} onClick={() => changeStatus(item.id)}>{item.r}</li>
              }
            }
            else {
              return <li className={`item-${item.id}`} key={item.id} onClick={() => changeStatus(item.id)}>{item.r}</li>
            }
          })}
        </ul>
      </div>
    </div>
  )
}

export default ChecklistBody
