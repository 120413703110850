import React, { useState } from 'react'
import BeforeStart from './checksection/BeforeStart'
import EngineStart from './checksection/EngineStart'
import BeforeTaxi from './checksection/BeforeTaxi'
import BeforeTakeOff from './checksection/BeforeTakeOff'
import AfterTakeOff from './checksection/AfterTakeOff'
import BeforeLanding from './checksection/BeforeLanding'
import AfterLanding from './checksection/AfterLanding'
import Shutdown from './checksection/Shutdown'

const ChecklistComp = () => {
  const [check, setCheck] = useState('')
  const showCheck = (type) => {
    if (type === check) {
      setCheck('')
    } else {
      setCheck(type)
    }
  }
  // Select between long (including daily checks) and short checklist
  const[shortCheck, setShortCheck] = useState(0)

  const abbrCheck = (status) => {

    if(status === 0) {
      setShortCheck(1)
    }
    else {
      setShortCheck(0)
    }
  } 

  return (
    <div className='col-12 col-md-10 checklist-container'>
      <div className="toggle-selector custom-control custom-switch text-center mb-3">
        <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange = {() => {abbrCheck(shortCheck)}}/>
        <label className="custom-control-label" htmlFor="customSwitch1">Short Checklist</label>
      </div>
      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('bes')
        }}
      >
        BEFORE ENGINE START
      </button>
      {check === 'bes' && <BeforeStart status={shortCheck}/>}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('es')
        }}
      >
        ENGINE START
      </button>
      {check === 'es' && <EngineStart status={shortCheck}/>}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('btaxi')
        }}
      >
        BEFORE TAXI
      </button>
      {check === 'btaxi' && <BeforeTaxi status={shortCheck}/>}

      {/* <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('taxi')
        }}
      >
        TAXI
      </button>
      {check === 'taxi' && <Taxi />} LATER PROBABLY DELETED THIS SECTION */}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('bto')
        }}
      >
        BEFORE TAKE OFF
      </button>
      {check === 'bto' && <BeforeTakeOff status={shortCheck}/>}

      {/* <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('hover')
        }}
      >
        HOVER
      </button>
      {check === 'hover' && <Hover />} PROBABLY DELETE LATER */}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('ato')
        }}
      >
        AFTER TAKE OFF
      </button>
      {check === 'ato' && <AfterTakeOff />}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('bland')
        }}
      >
        LANDING
      </button>
      {check === 'bland' && <BeforeLanding />}

      {/* <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('landing')
        }}
      >
        LANDING
      </button>
      {check === 'landing' && <Landing />} PROBABLY DELETED LATER */}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('aland')
        }}
      >
        AFTER LANDING
      </button>
      {check === 'aland' && <AfterLanding />}

      <button
        className='btn btn-gold btn-block mb-4'
        onClick={() => {
          showCheck('shut')
        }}
      >
        SHUTDOWN
      </button>
      {check === 'shut' && <Shutdown />}
    </div>
  )
}

export default ChecklistComp
