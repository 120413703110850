import React from 'react'
import ChecklistBody from './ChecklistBody'

const EngineStart = (props) => {
  const items = [
    { c: 'FSB / NS', r: 'ON', id: 1 },
    { c: 'EAPS', r: 'OFF', id: 2 },
    { c: 'Master Start', r: 'ON', id: 3 },
    { c: 'Clearance', r: 'Received', id: 4 },
    { c: 'Rotor Area', r: 'Clear', id: 5 },
    { c: 'First Engine', r: 'Start', id: 6 },
    { c: 'Inverters', r: 'ON', id: 7 },
    { c: 'EXT Power', r: 'OFF', id: 8 },
    { c: 'Stby ATT Indicator', r: 'ON', id: 9 },
    { c: 'Rotor Brake', r: 'OFF', id: 10 },
    { c: 'ECL', r: '65% NR', id: 11 },
    { c: 'Flight Controls / Servos', r: 'Check', id: 12 },
    { c: 'ECL', r: 'FLY', id: 13 },
    { c: 'Generator', r: '< 100 AMPs', id: 14 },
    { c: 'Second Engine', r: 'Start', id: 15 },
    { c: 'Master Start', r: 'OFF', id: 16 },
    { c: 'ECLs', r: 'FLY', id: 17 },
    { c: 'AC GEN (above 92%)', r: 'Check ON', id: 18 },
    { c: 'Fuel Levers', r: 'DIRECT', id: 19 },
    { c: 'EEC Lights', r: 'One per Engine', id: 20 },
    { c: 'ESS BUS Recovery', r: 'TEST', interval: 'daily', id: 21 },
    { c: 'WX Radar', r: 'STBY', id: 22 },
    { c: 'TCAS', r: 'STBY', id: 23 },
    { c: 'EFIS / NAV / Radios', r: 'ON', id: 24 },
    { c: 'UNS', r: 'ON', id: 25 },
    { c: 'APs', r: 'TEST', interval: 'daily', id: 26 },
    { c: 'EEC Status', r: 'TEST', interval: 'daily', id: 27 },
    { c: 'Fire Detector (bagg.)', interval: 'daily', r: 'TEST', id: 28 },
  ]
  return <ChecklistBody items={items} status={props.status}/>
}

export default EngineStart
