import React, { Fragment } from 'react'
import { WBForm } from '../components/WBForm'

const WB = () => {
  return (
    <Fragment>
      <WBForm />
    </Fragment>
  )
}

export default WB
