import React from 'react'

const MEL = () => {
  return (
    <div className='container'>
      <h2>Work in progress!</h2>
    </div>
  )
}

export default MEL
