import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <nav className='navbar navbar-grey bg-gold mb-3'>
      <div className="nav-items">
        <Link to='/'>
          <p className='navbar-brand'>
            Sikorsky S-76B
          </p>
        </Link>
        <Link to='/'>
        <i className="fas fa-home"></i>
        </Link>
      </div>
    </nav>
  )
}

export default Header
